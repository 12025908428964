import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import TermineMargin from "../../components/termine-margin"
import WirUeberUnsSubmenu from "../../components/wirueberuns/wirueberuns_submenu"
import WirUeberUnsContentHeader from "../../components/wirueberuns/wirueberuns_contentHeader"
import { graphql } from "gatsby"

export const query = graphql`
  {
    allSanitySiteSettings {
      nodes {
        motto
      }
    }
  }
`

const ausfluege = ({data}) => (
  <Layout>
    <SEO title="Ausfluege"/>

    <WirUeberUnsContentHeader headline="UNSERE AUSFLÜGE" motto={data.allSanitySiteSettings.nodes[0].motto} />

    <section id="content_2_schule">
      <WirUeberUnsSubmenu />

      <article id="con_2_rechts">

        <h3 className="small_sub"><span style={{color:"#007c34"}}>AUSFL&Uuml;GE</span></h3>

        <p className="text"><span style={{fontWeight: "bold"}}>In allen vier Schuljahren werden Klassenausfl&uuml;ge in die n&auml;here Umgebung unternommen:</span>
        </p><br />
        <ul>
          <li className="aufzaehlung_gruener_kreis">Zoo</li>
          <li className="aufzaehlung_gruener_kreis">Tierpark</li>
          <li className="aufzaehlung_gruener_kreis">Museum</li>
          <li className="aufzaehlung_gruener_kreis">Freilichtmuseum</li>
          <li className="aufzaehlung_gruener_kreis">Theater-/Kinobesuch in der Adventszeit</li>
          <li className="aufzaehlung_gruener_kreis">Abschlussfahrt (z.B. Minden Schachtschleuse und/oder
            Bergwerkmuseum Kleinenbremen)
          </li>
        </ul>

      </article>

      <TermineMargin />
    </section>
  </Layout>
)

export default ausfluege