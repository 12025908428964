import React from "react"
import { Link } from "gatsby"

const WirUeberUns_Submenu = () => (
  <nav className="nav_schule">
    <ul>
      <li><Link className="menu" to="/wirueberuns/leitbild">Unser Leitbild</Link></li>
      <li><Link className="menu" to="/wirueberuns/schwerpunkte">Schwerpunkte</Link></li>
      <li><Link className="menu" to="/wirueberuns/projekte">Projekte</Link></li>
      <li><Link className="menu" to="/wirueberuns/ausfluege">Ausflüge</Link></li>
      <li><Link className="menu" to="/wirueberuns/glanzlichter">Glanzlichter</Link></li>
      <li><Link className="menu" to="/wirueberuns/meilensteine">Meilensteine</Link></li>
      <li><Link className="menu" to="/wirueberuns/bildergalerien">Bildergalerien</Link></li>
    </ul>
    <div className="shadow_links"></div>
  </nav>
)

export default WirUeberUns_Submenu
